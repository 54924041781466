<template>
  <div>
    <div v-if="checkSticky" class="header-placeholder" />
    <div
      class="sf-header-custom_container"
      :class="{ 'header--sticky': checkSticky }"
    >
      <div
        class="sf-header--has-navigation-inside"
        :class="{ 'header-on-top': isSearchOpen }"
      >
        <div class="sf-header-custom">
          <div class="sf-header__item_left">
            <HeaderNavigation class="desktop-only" :menu="menu" />
            <HeaderNavigationMobile class="mobile-only" :menu="menu" />
          </div>
          <div
            class="sf-header__item_center"
            :class="{ 'open-searchbar': isSearchBarOpen }"
          >
            <HeaderLogo />
          </div>
          <div class="sf-header__item_right">
            <HeaderIconsDesktop
              class="desktop-only"
              :cart-total-items="cartTotalItems"
              @cartSidebarClick="toggleCartSidebar"
            />
            <HeaderIconsMobile
              class="smartphone-only"
              :cart-total-items="cartTotalItems"
              :icons="headerIcons"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useUiHelpers, useUiState, useWindow } from '~/composables';
import {
  useCart,
  useCategorySearch,
  useFacet,
  useMenu,
  useUser,
} from '@gemini-vsf/composables';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive';
import debounce from 'lodash.debounce';
import HeaderIconsDesktop from '~/components/Header/HeaderIcons/HeaderIconsDesktop.vue';
import HeaderIconsMobile from '~/components/Header/HeaderIcons/HeaderIconsMobile.vue';
import HeaderNavigationMobile from './Header/Navigation/HeaderNavigationMobile.vue';

export default defineComponent({
  components: {
    HeaderIconsMobile,
    HeaderIconsDesktop,
    HeaderNavigation,
    HeaderLogo,
    HeaderNavigationMobile,
  },
  directives: { clickOutside },
  props: {
    checkSticky: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      toggleSearchBar,
    } = useUiState();
    const { handleBodyClass } = useWindow();
    const { setTermForUrl, getCatLink, getFacetsFromURL } = useUiHelpers();
    const { isAuthenticated } = useUser();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { cart } = useCart();
    const { menu, search: searchMenu } = useMenu('common');
    const term = ref(getFacetsFromURL().term);
    const result = ref(null);
    const { result: searchResult, search: productsSearch } =
      useFacet('AppHeader:Products');
    const { search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );
    const isSearchBarOpen = ref(false);
    const isSearchOpen = ref(false);
    const productSearchResults = ref(null);
    const accountIcon = computed(() =>
      isAuthenticated.value ? 'profile_fill' : 'profile'
    );

    const headerIcons = ref({
      search: '/icons/components/search.svg',
      cart: '/icons/components/bag.svg',
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.$fixUrlForCurrentLocale('/my-account'));
      } else {
        toggleLoginModal();
      }
    };

    useFetch(async () => {
      await searchMenu({ code: 'mainmenu' });
    });

    const closeSearch = () => {
      if (!result) {
        if (!isSearchOpen.value) return;

        term.value = '';
        isSearchOpen.value = false;
      }
    };

    // FixMe: handle search results
    const handleSearch = debounce(async (paramValue) => {
      handleBodyClass('addClass', 'loading_search');
      term.value = !paramValue.target ? paramValue : paramValue.target.value;

      await Promise.all([
        productsSearch({
          itemsPerPage: 12,
          term: term.value,
        }),
        categoriesSearch({ filters: { name: { match: `${term.value}` } } }),
      ]);

      isSearchOpen.value = true;
      result.value = {
        products: searchResult.value?.data?.items,
        categories: {},
      };
      handleBodyClass('removeClass', 'open_menu');
      handleBodyClass('removeClass', 'loading_search');
    }, 300);

    const handleSearchBar = () => {
      toggleSearchBar();
      isSearchBarOpen.value = !isSearchBarOpen.value;
    };

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      menu,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      handleSearch,
      term,
      isSearchBarOpen,
      headerIcons,
      getCatLink,
      handleAccountClick,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleMobileMenu,
      closeSearch,
      handleSearchBar,
    };
  },
});
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
html.open_menu_html {
  overflow: hidden;
}
body.open_menu {
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  #CookiebotWidget,
  iframe {
    z-index: 2 !important;
  }
}
body.loading_search {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
  position: relative;
  top: 0;
  left: 0;
}
body.loading_search::before {
  content: '';
  top: 50%;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: rotate 0.8s infinite linear;
  -webkit-animation: rotate 0.8s infinite linear;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
  @include to-portrait-max {
    z-index: 99999999;
  }
}

.header-placeholder {
  display: none;
}

//.banner-home {
//  margin-top: -94px;
//}
// --- Custom Style ---
.sf-header-custom_container {
  margin: 0 auto;
  height: 3.75rem;
  will-change: transform;
  width: 100%;
  max-width: 100vw;
  background: var(--c-white);
  z-index: 3;
  position: relative;
  border-bottom: 1px solid var(--c-super-light-grey);
  @include to-portrait-max {
    border-bottom: 1px solid var(--c-grey);
  }
  &.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .header-on-top {
    z-index: 3;
  }
  .sf-header--has-navigation-inside {
    height: 100%;
  }
  .cart-badge {
    position: absolute;
    bottom: 40%;
    left: 40%;
  }
}
.header-placeholder {
  display: block;
  height: 3.75rem;
}

.sf-header-custom {
  align-content: center;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  grid-template-rows: 3.75rem;
  //gap: 2.273rem;
  margin: 0 1.563rem;
  height: 100%;
  .sf-header__item_right {
    .sf-header__icons {
      gap: 1.25rem;
    }
  }
}
#header_container {
  .sf-overlay {
    display: none;
  }
}
#layout #home {
  overflow: inherit;
}
.sf-header__item_ {
  &left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 3.182rem;
    .sf-header__action {
      display: block;
      margin: 0;
    }
  }
  &center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
// .sf-header--has-navigation-inside .sf-header__item_right .sf-search-bar {
//   margin: 0 36px 0 0;
//   width: 180px;
//   height: auto;
//   flex: auto;
// }
.sf-header__item_right .sf-header__icons {
  display: flex;
  align-items: center;
  margin: 0 0.875rem 0 0;
  .sf-header__action {
    --header-action-margin: 0;
    padding: 0 0.938rem;
    &:last-child {
      padding-right: 0;
    }
    @include to-mobile-max {
      padding: 0;
    }
    .sf-badge--number {
      background-color: var(--c-grey);
      color: var(--c-black);
    }
  }
  .sf-header__search-image {
    border-right: 1px solid var(--c-transparent-grey);
    padding-right: var(--space-s);
  }
  .sf-header__search-image.dark-divider {
    border-right: 1px solid var(--c-grey);
  }
  .sf-header__wishlist-image {
    padding-left: var(--space-s);
  }
}
.sf-header__cart,
.sf-header__user {
  margin: 0;
}
.sf-header__item_right .sf-image--wrapper {
  display: block;
}
.sf-header__user-image {
  img {
    width: 1.625rem;
    object-fit: contain;
  }
}
.sf-header__wishlist-image {
  img {
    width: 1.688rem;
    object-fit: contain;
  }
}
.sf-header__cart-image {
  img {
    // width: 23px;
    object-fit: contain;
  }
}
.item_icon {
  margin-right: 1.563rem;
  img {
    display: block;
  }
}
.item_icon:last-child {
  margin-right: 0;
}
.sf-header__action_menu {
  margin-right: 2.625rem;
}
.mega_top {
  display: flex;
  align-items: center;
  margin-bottom: 4.25rem;
  @include for-desktop {
    display: block;
  }
  .container {
    display: none;
  }
  .close_menu {
    @include for-desktop {
      float: right;
    }
    .sf-header__action_menu {
      @include for-desktop {
        margin-right: 0;
      }
    }
  }
}
.heading h3 {
  font-size: 1.75rem;
  color: #222a24;
  line-height: 2.313rem;
  padding-bottom: 1.063rem;
  font-weight: 400;
}
.content {
  padding: 0;
  list-style: none;
  margin-top: 0.313rem;
  display: none;
  padding-left: 1.875rem;
  a {
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: #222a24;
    font-weight: 300;
    display: inline-block;
  }
}
.locales_custom {
  .sf-list {
    flex-direction: row-reverse;
  }
}
@media (max-width: $mobile-max) {
  .sf-header__wishlist-image {
    display: none;
  }
}
@include from-landscape-min {
  .sf-search-bar {
    margin: 0 1.5rem 0 0;
  }
}
//@media (max-width: 991px) {
//  .sf-header__item_right .sf-header__icons {
//    margin: 0;
//  }
//  .sf-header__list {
//    display: none;
//  }
//  .sf-header__action_menu {
//    margin-right: 25px;
//  }
//  .item_icon {
//    margin-right: 17px;
//  }
//}
@include from-landscape-min {
  .sf-header-custom_container {
    height: 5.875rem;
    transition: height 200ms ease-out;
    &.header--sticky {
      height: 4.375rem;
    }
    .sf-header-custom {
      display: grid;
      grid-template-columns: 40% 20% 40%;
      grid-template-rows: 100%;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0.313rem 0.938rem;
      height: inherit;
    }
    .sf-header__item_ {
      &left {
        max-width: unset;

        .sf-header__action {
          display: block;
          margin: 0;
        }
      }
      &right {
        margin-right: 1rem;

        .container .sf-list {
          display: none;
        }
      }
    }
    .mega_top .container {
      display: block;
      flex-shrink: 0;
    }
    .sf-header-custom_container {
      transform: translateY(46px);
    }
    .sf-header-custom {
      padding: var(--mobile-margin);
    }
    .sf-header__mail {
      display: none;
    }
    .banner-home {
      margin-top: -70px;
    }
    .sf-header__logo {
      height: 3.25rem;
    }
    .sf-header__logo-image {
      width: 9.438rem;
      display: block;
      object-fit: contain;
    }
    .sf-header__item_left {
      .sf-header__action {
        --header-action-margin: 0;
      }
    }
    .sf-header__item_right {
      .sf-search-bar__input::-webkit-input-placeholder,
      .sf-search-bar__input::placeholder {
        font-size: 12px;
        font-family: var(--font-family--primary);
        font-weight: 500;
      }
    }
    .mega_top {
      margin-bottom: 33px;
    }
    .close_menu {
      .sf-image {
        width: 17px;
        height: 17px;
        object-fit: contain;
      }
    }
    .mobile-only {
      display: none !important;
    }
  }
  .header-placeholder {
    display: block;
    height: 5.875rem;
  }
}
@include from-desktop-min {
  .sf-header-custom_container {
    max-width: var(--desktop-l-min);
  }
}
</style>
