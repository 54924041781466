<template>
  <div class="empty" />
</template>

<script>
import {
  ref,
  defineComponent,
  useRoute,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SetCjCookie',
  setup() {
    const cjQueryParam = ref('cjevent');
    const { $cookies } = useContext();
    const route = useRoute();
    const cjQueryParamValue = route.value.query?.[cjQueryParam.value] ?? '';
    if (cjQueryParamValue) {
      $cookies.set('cjevent', cjQueryParamValue);
    }
  },
});
</script>
