<template>
  <div class="footer-bottom" :class="isCheckout && 'footer-bottom__checkout'">
    <div class="footer-bottom__row">
      <div
        class="footer-bottom__column footer-country-switcher"
        @click="toggleCountrySidebar"
        :class="isCheckout && 'footer-country-switcher__hidden'"
      >
        <span class="footer-country-switcher__cta">
          {{ $t('Select your country and language') }}
        </span>
        <span class="footer-country-switcher__detail">
          {{ marketDetails }}
        </span>
      </div>
      <div class="footer-bottom__column">
        <div class="payments-logo">
          <SfImage
            class="scalapay-logo"
            :src="addBasePath('/icons/payment-methods/scalapay.svg')"
            alt="scalapay"
            width="100"
            height="30"
            placeholder=""
          />
          <SfImage
            class="footer__payment-image"
            :src="
              (footerPaymentContent && footerPaymentContent.images.desktop) ||
              addBasePath('/payments_logo.png')
            "
            :alt="
              (footerPaymentContent && footerPaymentContent.images.alt) ||
              'Payments'
            "
            width="320"
            height="30"
            placeholder=""
          />
        </div>
      </div>
    </div>
    <div class="footer-bottom__row">
      <div class="footer-bottom__column">
        <span>{{
          $t(
            `© Copyright ${currentYear} Biffi Boutique S.p.A. - All Rights Reserved P. IVA 05935720150`
          )
        }}</span>
      </div>
      <div class="footer-bottom__column">
        <div class="policy-link">
          <nuxt-link
            :to="`/${
              locale.includes('it')
                ? 'it/termini-condizioni'
                : `${locale}/terms-conditions`
            }`"
          >
            {{ $t('Terms and conditions') }}
          </nuxt-link>
          <nuxt-link
            :to="`/${
              locale.includes('it') ? 'it/privacy' : `${locale}/privacy-policy`
            }`"
          >
            {{ $t('Privacy policy') }}
          </nuxt-link>
          <nuxt-link
            :to="`/${
              locale.includes('it') ? 'it/cookie' : `${locale}/cookie-policy`
            }`"
          >
            {{ $t('Cookie policy') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfImage } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  useFetch,
  computed,
  useRoute,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useI18n } from '~/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useUiState } from '~/composables';

export default defineComponent({
  components: {
    SfImage,
  },
  setup() {
    const currentYear = new Date().getFullYear();
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(
      `footerBlock-${locale.includes('it') ? 'it' : 'en'}`
    );
    const footerSelector = `footer-${locale.includes('it') ? 'it' : 'en'}`;
    const footerPaymentContent = ref();
    const { toggleCountrySidebar } = useUiState();
    const route = useRoute();
    const isCheckout = computed(() =>
      route?.value?.fullPath?.includes('checkout')
    );

    useFetch(async () => {
      await loadBlocks({ identifiers: [footerSelector] });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      try {
        footerPaymentContent.value = blockData[footerSelector].data.payment;
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });

    const marketDetails = computed(() =>
      route.value?.fullPath.includes('it')
        ? 'IT - Italiano'
        : `${locale.toUpperCase()} - English`
    );

    return {
      addBasePath,
      currentYear,
      footerPaymentContent,
      locale,
      toggleCountrySidebar,
      marketDetails,
      isCheckout,
    };
  },
});
</script>

<style lang="scss">
.footer-bottom {
  max-width: 90rem;
  margin-left: 1.563rem;
  margin-right: 1.563rem;
  &__checkout {
    margin-top: 0;
  }
}
.footer-bottom__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  border-top: 0;
  padding-bottom: var(--space-xs);
  margin-bottom: 0;
  &:first-child {
    border-bottom: var(--space-xxxs) solid var(--c-grey);
    @include for-mobile {
      display: flex;
      flex-direction: column;
    }
  }
  .footer-country-switcher {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    @include pointer;
    @include font-12x16;
    &__cta {
      font-weight: 500;
    }
    &__hidden {
      visibility: hidden;
    }
  }
  @include from-landscape-min {
    grid-template-columns: 1fr 1fr;
    padding-bottom: var(--space-s);
    margin-bottom: var(--space-s);
    margin: 0 6.125rem;
    align-items: center;
  }
  .footer-bottom__column {
    a {
      text-decoration: none;
      font-size: var(--font-size-xxs);
      line-height: var(--font-line-height-m);
    }
    p,
    span {
      font-family: var(--font-family--secondary);
      font-size: var(--font-size-xxs);
      line-height: var(--font-line-height-m);
      color: var(--c-black);
    }
    .selected-lang {
      font-weight: var(--font-weight-light);
    }
    .policy-link {
      margin-top: var(--space-s);
      a {
        font-weight: var(--font-weight-regular);
        margin-right: var(--space-xxs);
        @include font-12x16;
        color: var(--c-black);
        @include from-landscape-min {
          margin-right: 2rem;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @include from-landscape-min {
        text-align: right;
        margin-top: 0;
      }
    }
    .payments-logo {
      margin-top: 4.188rem;
      .scalapay-logo {
        > img {
          margin: 0 0.9375rem 0 auto;
        }
      }
      @media screen and (max-width: 460px) {
        flex-direction: column;
        .scalapay-logo {
          > img {
            margin: 0 auto;
          }
        }
      }
    }
    .footer__payment-image {
      min-width: 320px;
      @media screen and (max-width: 460px) {
        transform: scale(0.8);
      }
    }
  }
  &:last-child {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-m);
    @include from-landscape-min {
      padding-top: var(--space-s);
    }
  }
}
@include from-landscape-min {
  .footer-bottom {
    margin: var(--space-xxl) auto;
    margin-bottom: 0;
    .sf-footer__container {
      .payments-logo {
        text-align: right;
      }
    }
    &__row {
      .footer-bottom__column {
        .payments-logo {
          text-align: right;
          margin-top: 0;
        }
      }
    }
    &__checkout {
      margin-top: 0;
    }
  }
}
@include to-landscape-max {
  .payments-logo {
    @include flex-center;
    margin-top: 1rem;
  }
}
</style>
