































































import { SfFooter, SfList, SfImage, SfMenuItem } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  useFetch,
  useRoute,
  computed,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import SfFooterColumnCustom from '~/components/Footer/SfFooterColumnCustom.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    SfFooterColumnCustom,
  },
  setup() {
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(
      `footerBlock-${locale.includes('it') ? 'it' : 'en'}`
    );
    const footerSelector = `footer-${locale.includes('it') ? 'it' : 'en'}`;
    const footerLinksContent = ref();
    const footerSocialContent = ref();
    const route = useRoute();

    const isCheckout = computed(() =>
      route?.value?.fullPath?.includes('checkout')
    );

    useFetch(async () => {
      await loadBlocks({ identifiers: [footerSelector] });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      try {
        const rawLinksData = blockData[footerSelector].data['links-column'];
        const rawSocialData = blockData[footerSelector].data.social;
        footerLinksContent.value = rawLinksData.filter(
          (item) => item === null || true
        );
        footerSocialContent.value = rawSocialData.filter(
          (item) => item === null || true
        );
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });
    return {
      addBasePath,
      footerLinksContent,
      footerSocialContent,
      isCheckout,
    };
  },
});
